<template>
  <div class="flex flex-col w-full my-2 text-input">
    <div class="relative flex flex-row justify-between w-full">
      <p class="px-3 text-lg font-extrabold text-left text-primary">{{label}}</p>
      <div
        v-if="toolTip.length > 0"
        class="pl-2 text-sm text-content"
      >

        <info-icon
          class="cursor-pointer"
          @click="showToolTip = !showToolTip"
        />
        <div
          v-if="showToolTip"
          class="`
              absolute border-2 border-contrast right-0 z-20 flex flex-col items-stretch
              top-7 w-11/12 rounded-b-lg rounded-l-lg px-2 py-1 bg-backfill
          `"
        >
          <p class="pb-2">
              {{toolTip}}
          </p>
          <a
            v-for="(link, index) in links" :key="`${link.name}-${index}`"
            :href="link.url"
            class="w-full text-right underline cursor-pointer"
            target="_blank"
          >
            {{link.title}}
          </a>
        </div>
      </div>
    </div>

    <div class="flex justify-between w-full px-2">

      <input
        :ref="label"
        autocomplete="off"
        @keyup="$emit('update:input', $event.target.value)"
        @change="$emit('update:input', $event.target.value)"
        :name="label"
        :label='label'
        v-model='val'
        :type="type"
        :valid="valid"
        :disable="false"
        class="`
          flex-grow px-3 py-1 font-bold rounded-lg focus:outline-none bg-backfill
          min-w-0
        `"
      >
    </div>
  </div>
</template>

<script>
import InfoIcon from 'vue-material-design-icons/Information.vue';

export default {
  name: 'TextInputToolTip',
  components: {
    InfoIcon,
  },
  data() {
    return {
      editMode: false,
      val: '',
      showToolTip: false,
    };
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    input: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      required: false,
      default: '',
    },
    type: {
      type: String,
      required: false,
      default: 'text',
      validator: (val) => ['password', 'email', 'text', 'number'].includes(val),
    },
    valid: {
      type: Boolean,
      required: false,
      default: true,
    },
    toolTip: {
      type: String,
      required: true,
    },
    links: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  created() {
    this.val = this.input;
  },
  methods: {
    saved() {
      if (this.valid) {
        this.editMode = !this.editMode;

        if (this.editMode === false) {
          this.$emit('save');
        } else {
          document.querySelector(`input[name="${this.label}"]`).click();
        }
      }
    },
  },
};
</script>

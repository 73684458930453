<template>
    <interior-page-content-container
      nav-selection="calculators"
      @back-click="backNav"
      :show-bottom-nav="false"
      :show-back-button-top-left="true"
      :show-back-button-in-nav="true"
      backButtonText="Calculators"
    >

      <template #title>
        Monthly cost
      </template>

      <template #content>
        <div class="`
            flex flex-col items-center self-center justify-center flex-shrink-0
            pb-4 mb-10 mx-7 w-72 md:w-96 pt-2
            `"
        >
            <div class="w-full mb-3 text-2xl font-bold text-left text-content old-costs">
              Current Home: {{format(monthlyCostsTotal)}}
            </div>

            <text-input-tool-tip
              v-for="input in monthlyCosts" :key="input.label+ 'monthlyCosts'"
              :label="input.label"
              :input.sync="input.value"
              :tool-tip="input.toolTip"
              type="number"
              :valid="true"
              class="mb-4"
            />

            <div class="w-full mb-3 text-2xl font-bold text-left text-content new-costs">
              New Home: {{format(newHomeMonthlyCostsTotal)}}
            </div>

            <text-input-tool-tip
              v-for="input in newHomeMonthlyCosts" :key="input.label+ 'newHomeMonthlyCosts'"
              :label="input.label"
              :input.sync="input.value"
              :tool-tip="input.toolTip"
              type="number"
              :valid="true"
              class="mb-4"
            />

            <p class="pb-5 text-left text-content">
                The total monthly charges have changed by the amount shown below.
                Negative means they have decreased, positive means they have increased
            </p>

            <p class="mb-10 text-2xl text-content total">
                {{format(total)}}
            </p>

        </div>
      </template>

    </interior-page-content-container>
</template>

<script>
import InteriorPageContentContainer from '@/components/shared/containers/InteriorPageContentContainer/InteriorPageContentContainer.vue';
import TextInputToolTip from '@/components/shared/inputs/TextInputToolTip.vue';

import * as calculatorsInfo from '@/assets/json/calculators.json';

export default {
  name: 'CalculatorDownsizing',
  components: {
    InteriorPageContentContainer,
    TextInputToolTip,
  },
  data() {
    return {
      monthlyCosts: {},
      newHomeMonthlyCosts: {},
    };
  },
  computed: {
    monthlyCostsTotal() {
      return this.monthlyCosts.reduce(
        (pastVal, curVal) => pastVal + this.strToNum(curVal.value), 0,
      );
    },
    newHomeMonthlyCostsTotal() {
      return this.newHomeMonthlyCosts.reduce(
        (pastVal, curVal) => pastVal + this.strToNum(curVal.value), 0,
      );
    },
    total() {
      return this.newHomeMonthlyCostsTotal - this.monthlyCostsTotal;
    },
  },
  created() {
    this.monthlyCosts = calculatorsInfo.monthlyCosts.monthlyCosts;
    this.newHomeMonthlyCosts = calculatorsInfo.monthlyCosts.newHomeMonthlyCosts;
  },
  methods: {
    format(num) {
      return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(num);
    },
    strToNum(str) {
      if (str === '') return 0;
      return parseFloat(str);
    },
    backNav() {
      this.$router.push({ name: 'Calculators' });
    },
  },
};
</script>

<style>

</style>
